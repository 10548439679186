import React from 'react'
// import TempFooterContent from '../../images/tempFooterContent'
import './footer.scss'
import Logo from '../../images/footer.png'
import { Link } from 'gatsby'

const Footer = () => {
  const onSubmit = (e) => {
    e.preventDefault()
    alert('Email Has Been Submitted')
  }
  return (
    <div className="footer">
      {/* <TempFooterContent
        height="49vh"
        width="100vw"
        style={{ position: 'absolute' }}
      /> */}

      <div className="footer-container">
        <div className="footer-section-1">
          <div className="newsletter-section">
            <div className="footer-logo">
              <img src={Logo} alt="akta-logo" />
            </div>
            <div className="newsletter-description">
              <span>
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Volutpat id enim ac egestas nibh ac tellus. */}
              </span>
            </div>
            <div className="newsletter-form">
              <form onSubmit={onSubmit}>
                <input
                  placeholder="Subscribe to our newsletter"
                  type={`text`}
                  className="input-form"
                />
                <button type="submit" className="submit-newsletter-button">
                  Subscribe
                </button>
              </form>
            </div>
          </div>
          <div className="footer-links">
            <div className="footer-menu">
              <div className="footer-menu-header">
                <span>Resources</span>
              </div>
              <div className="footer-menu-body">
                <Link to="/in-progress">
                  <span>Documentation</span>
                </Link>
                <Link to="/in-progress">
                  <span>Report an issue</span>
                </Link>
                <Link to="/faqs">
                  <span>FAQs</span>
                </Link>
                <Link to="/in-progress">
                  <span>Careers</span>
                </Link>
              </div>
            </div>
            <div className="footer-menu">
              <div className="footer-menu-header">
                <span>How is it</span>
              </div>
              <div className="footer-menu-body">
                <Link to="/in-progress">
                  <span>For Business</span>
                </Link>
                <Link to="/">
                  <span>For Research</span>
                </Link>
                <Link to="/respondent">
                  <span>For Respondent</span>
                </Link>
              </div>
            </div>
            <div className="footer-menu">
              <div className="footer-menu-header">
                <span>Contact Us</span>
              </div>
              <div className="footer-menu-body">
                <Link to="mailto:info@aktaanalytics.com">
                  <span>info@aktaanalytics.com</span>
                </Link>
                <Link to="/help-center">
                  <span>Help Center</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-divider"></div>
        <div className="footer-section-2">
          <div className="rights-reserved">
            <span>© 2010 - 2022 AKTA Analytics • All Rights Reserved</span>
          </div>
          <div className="terms-and-policies">
            <span className="no-text-decoration">
              <Link to="/terms-of-service"> Terms of Service </Link>|
              {/* <Link to="/in-progress"> Terms of Service </Link>| */}
              <Link to="/in-progress"> Privacy Policy </Link>|
              <Link to="/in-progress"> Status Updates </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
