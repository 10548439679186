import React from 'react'
import './hero.scss'

export const CenteredHero = ({
  pageTitle,
  pageInfo,
  pageDescription,
  background,
  h1Position,
  pageDescriptionPosition,
  headerTitleColor
}) => {
  const defaultData = {
    pageTitle: '',
    pageInfo: 'The easiest way to take your business to the next level.',
    pageDescription: `Create simple and efficient forms in a super fast way with just a few
    steps. We are always available to assist you whenever you need our
    help.`,
    defaultBackground: 'bg-light-green ',
    h1Position: '',
    pageDescriptionPosition: 'mx-auto mobile-pad',

  }
  return (
    <div className={background ? `hero-container ${background}` : `hero-container blog-image text-black `}>
      <div className="mx-auto text-center ">
        <span className={headerTitleColor ?? `header-title`}>{pageTitle ?? defaultData.pageTitle} </span>

        <h1 className={`${h1Position} mobile-w45` ?? defaultData.h1Position}>
          {pageInfo ?? defaultData.pageInfo}
        </h1>
        <div
          className={
            pageDescriptionPosition + " mobile-pad" ?? defaultData.pageDescriptionPosition
          }
        >
          <span className='font-md'>{pageDescription ?? defaultData.pageDescription}</span>
        </div>
      </div>
    </div>
  )
}
