import "./style.scss"
import richTextRenderer from '../../utils/richTextRenderer'
import { graphql } from 'gatsby';
import React, { useState } from 'react'

import Footer from '../../components/Footer';
import SampleSizeCTA from "../../Contentful/SampleSize";
import { CenteredHero } from "../../components/Hero/CenteredHero";
import { WorkCard } from "../../components/Card/WorkCard";
import { ReportsData, WorksData } from "../../utils/GraphQL/reportsQueryData";


const CATEGORIES = {
    "ALL": "ALL",
    "SOFTWARE": "SOFTWARE",
    "RECOGNITION": "RECOGNITION",
    "TEAM_LEADERSHIP": "TEAM_LEADERSHIP",
    "ALIGNMENT": "ALIGNMENT",
}

const headerData = {
    pageTitle: 'Success stories',
    pageInfo:
        'Discover why businesses love and support us by entrusting us to deliver solutions.',
    pageDescription: ``,
    defaultBackground: 'bg-pink',
    h1Position: `mx-auto w-50 mt-2 mb-2`,
    pageDescriptionPosition: 'mx-auto w-38 ft-sz-10',
    headerTitleColor: `header-title-color-white`,
    detailDescription: "",
    services: ["UI & UX Design", "Web Development", "APIs", "CRM", "Plugins", "SEO", "Reporting Dashboard"],
    industry: "Consulting",
    imageUrl: "https://images.unsplash.com/photo-1697615235189-13e74536bbf9?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw0fHx8ZW58MHx8fHx8"
}

const worksData = [
    {
        "title": "Sample 1",
        "tags": [
            "#tag1", "#tag2", "#tag3"
        ],
        "backgroundColor": "bg-green",
        "image": "https://images.unsplash.com/photo-1697615235189-13e74536bbf9?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw0fHx8ZW58MHx8fHx8",
        "category": "SOFTWARE"
    },
    {
        "title": "Sample 2",
        "tags": [
            "#tag1", "#tag2", "#tag3"
        ],
        "backgroundColor": "bg-pink",
        "image": "https://images.unsplash.com/photo-1697507803481-5724f26a7b50?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxNHx8fGVufDB8fHx8fA%3D%3D",
        "category": "RECOGNITION"
    },
    {
        "title": "Sample 3",
        "tags": [
            "#tag1", "#tag2", "#tag3"
        ],
        "backgroundColor": "bg-yellow",
        "image": "https://images.unsplash.com/photo-1696461353431-32c529d4585d?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxOXx8fGVufDB8fHx8fA%3D%3D",
        "category": "TEAM_LEADERSHIP"
    },
    {
        "title": "Sample 4",
        "tags": [
            "#tag1", "#tag2", "#tag3", "#tag4", "#tag5"
        ],
        "backgroundColor": "bg-green",
        "image": "https://images.unsplash.com/photo-1696590925159-8f6257ba48b2?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzNHx8fGVufDB8fHx8fA%3D%3D",
        "category": "ALIGNMENT"
    }
];

const Works = () => {

    const {
        allContentfulWorks: { nodes: worksData },
    } = ReportsData()


    console.log({ worksData })


    const [selectedCategory, setSelectedCategory] = useState(worksData);




    const categoryButton = (args) => {

        if (args === CATEGORIES.ALL) {
            setSelectedCategory(worksData)
        } else {
            console.log({ args })

            const mainCategory = worksData?.filter((value) => value?.category === args);
            setSelectedCategory(mainCategory)
        }



    }
    return (
        <div className="works-container">
            <div>
                <CenteredHero
                    background={headerData.defaultBackground}
                    pageDescription={headerData?.pageDescription}
                    pageInfo={headerData.pageInfo}
                    pageTitle={headerData.pageTitle}
                    h1Position={headerData.h1Position}
                    headerTitleColor={headerData.headerTitleColor}
                    pageDescriptionPosition={headerData.pageDescriptionPosition}
                />
            </div>
            <div className="col p-auto w-auto">
                <div className="row  mx-auto my-auto jt-cn ">
                    <button onClick={(e) => categoryButton(CATEGORIES.ALL)} className="rounded p-6 bt-pink-bg bt-active pointer">All</button>
                    <button onClick={(e) => categoryButton(CATEGORIES.SOFTWARE)} className="rounded p-6 bt-pink-bg ml-5 pointer">Software</button>
                    <button onClick={(e) => categoryButton(CATEGORIES.RECOGNITION)} className="rounded p-6 bt-pink-bg ml-5 pointer">Recognition</button>
                    <button onClick={(e) => categoryButton(CATEGORIES.TEAM_LEADERSHIP)} className="rounded p-6 bt-pink-bg ml-5 pointer">Team Leadership</button>
                    <button onClick={(e) => categoryButton(CATEGORIES.ALIGNMENT)} className="rounded p-6 bt-pink-bg ml-5 pointer">Alignment</button>
                </div>
                <div className="col mx-auto  jt-cn mt-2  h-auto">
                    <div className="grid-col">
                        {
                            selectedCategory && selectedCategory.map((value, index) => (
                                <WorkCard
                                    slug={value?.slug}
                                    backgroundColor={value?.backgroundColor}
                                    category={value?.category}
                                    imageUrl={value?.image ?? headerData?.imageUrl}
                                    tags={value?.tags}
                                    title={value?.title}
                                    key={index}
                                />
                            ))
                        }

                    </div>
                </div>
            </div>
            <div>
                <SampleSizeCTA buttonTitle={'Create Survey'} />
                <Footer />
            </div>
        </div>
    )
}

export default Works;