import React from 'react'
import './style.scss'
import { Link } from 'gatsby'
export const WorkCard = ({
    backgroundColor,
    imageUrl,
    title,
    category,
    slug,
    tags

}) => {
    return (
        <div className='work-card-container col h-auto '>
            <div className={`row jt-centre w-auto ${backgroundColor ?? 'bg-green'} image-fit-contain rounded-sm`}>
                <div className='row  w-80  vh-30 mt-1-im image-fit-contain ' >
                    <Link to={`/works/work-details/${slug}`} className='col image-container '>
                        <img className={`rounded-sm`} src={imageUrl ?? `https://instagram.facc1-1.fna.fbcdn.net/v/t39.30808-6/385067785_18292371703134335_3790785224149021603_n.jpg?stp=dst-jpg_e15_p480x480&efg=eyJ2ZW5jb2RlX3RhZyI6ImltYWdlX3VybGdlbi4xNDQweDE3ODcuc2RyIn0&_nc_ht=instagram.facc1-1.fna.fbcdn.net&_nc_cat=111&_nc_ohc=ofUOijr6PR8AX8XZV8S&edm=ACWDqb8AAAAA&ccb=7-5&ig_cache_key=MzIwNTUxODk3NTYwNDY5OTc3MQ%3D%3D.2-ccb7-5&oh=00_AfAYvCmNFaH44j-jRGLA3n6muQnrTaujaMSo1IeV8dWhqQ&oe=653A6638&_nc_sid=ee9879`} />
                    </Link>
                </div>
            </div>
            <div className='mt-10 font-bold'><span>{title ?? 'Sample Title'}</span></div>
            <div className='mt-10 row mb-5'>
                {tags?.length ? tags?.map(
                    (value, index) => {
                        if (index < 3) {
                            return (
                                <span className={`tag-bg-container rounded-sm ${index > 0 ? 'ml-5' : ''}`}>{value}</span>
                            )
                        } else {
                            return (
                                <span className={`tag-bg-container rounded-sm ${index > 0 ? 'ml-5' : ''}`}>...</span>
                            )
                        }
                    }
                ).slice(0, 4) : (
                    <>
                        <span className='tag-bg-container rounded-sm'>No Tags</span>
                    </>
                )
                }
            </div>
        </div >
    )
}
