import React from 'react'
import { Link } from 'gatsby'
import CTA from '../../components/CTA'
import './samplesize.scss'

const SampleSizeCTA = ({ data, buttonTitle }) => {
  return (
    <div className="create">
      <div className="sampleSize">
        {data ??
          'We will help you ask the right questions in order to gain valuable insights and help your business grow to the top'}
        {/* Update This Section */}
      </div>

      <CTA height="50px" width="200px" size="14px" type="secondary">
        <Link to="/surveys">{buttonTitle ?? `Create a survey`}</Link>
      </CTA>
    </div>
  )
}

export default SampleSizeCTA
