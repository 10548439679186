import { useStaticQuery, graphql } from 'gatsby'
export const ReportsData = () => {
  const queryData = useStaticQuery(graphql`
    query myReports {
      allContentfulReports(sort: { order: ASC, fields: createdAt }) {
        nodes {
          contentful_id
          createdAt(formatString: "DD/MM/YYYY")
          imageUrl
          slug
          title
          shortDescription
        }
      }
      allContentfulWorks(sort: { order: DESC, fields: createdAt }) {
        nodes {
          id
          title
          slug
          tags
          pageInfo
          backgroundColor
          
        }
      }
    }
  `)

  return queryData
}
